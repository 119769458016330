<template>
  <div class="overflow-x-auto">
    <table class="table">
      <thead>
        <tr class="text-center">
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            VA
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
          >
            SEMESTER
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
          >
            JUMLAH
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
          >
            SUBCOMP
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
          >
            PRODUK
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
          >
            STATUS
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in listTagihanJupiter" :key="index">
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ item.NO_VA }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ item.TahunAkademik }} {{ item.KD_Semester }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ item.Tagihan }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ item.SubComp }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs"
          >
            {{ item.NamaProduk }} ({{ item.KodeProduk }})
          </td>

          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            <div
              v-if="item?.Ada_Transaksi == 'Y'"
              class="py-1 px-2 rounded-full text-xs bg-green-400 text-white text-center cursor-pointer font-medium"
            >
              Ada Transaksi
            </div>
            <div
              v-else-if="item?.Ada_Transaksi == 'T'"
              class="py-1 px-2 rounded-full text-xs bg-red-400 text-white text-center cursor-pointer font-medium"
            >
              Belum Dibayar
            </div>
            <div
              v-if="item?.Validasi == 'Y'"
              class="py-1 px-2 rounded-full text-xs bg-green-500 text-white text-center cursor-pointer font-medium"
            >
              Telah Divalidasi
            </div>

            <button
              v-if="item?.Ada_Transaksi == 'T'"
              class="btn btn-sm btn-warning py-0 mt-2"
              @click="hapusTagihan(item)"
            >
              <Trash2Icon class="w-4 mr-2" />
              Hapus
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "TableTagihanMhs",
  props: {
    listTagihanJupiter: {
      type: Array,
      default: null,
    },
    model: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      listMhs: [],
    };
  },
  computed: {},
  watch: {
    model: {
      handler: function (h) {},
      immediate: true,
    },
  },
  methods: {

    hapusTagihan(btag) {
      this.$emit("hapusBiayaTagihan", btag);
    },
  },
};
</script>

<style scoped></style>
